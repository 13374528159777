import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import axios from 'axios';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome';
import '@fortawesome/fontawesome';

// 创建一个 Vue 应用实例
const app = createApp(App);

// 注册所有 Element Plus 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 配置 Font Awesome
config.autoAddCss = false;
library.add(fas);

// 设置 axios 全局基础 URL
axios.defaults.baseURL = process.env.API_ROOT || 'https://test2.dd2a.net';

// 使用路由和 Element Plus
app.use(router);
app.use(ElementPlus);

// 挂载 Vue 应用实例
app.mount('#app');
