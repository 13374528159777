<template>
  <el-container class="login-container">
    <el-form ref="loginForm" @submit.prevent="onSubmit" label-position="top">
      <el-form-item label="手机号">
        <el-input v-model="form.phoneNumber" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.password" type="password" placeholder="密码"></el-input>
      </el-form-item>
      <!-- <el-form-item label="图片校验码">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input v-model="form.imgCaptcha" placeholder="图片校验码"></el-input>
          </el-col>
          <el-col :span="8">
            <img :src="captchaImage" class="captcha-img" @click="refreshCaptcha" />
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="短信验证码">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input v-model="form.noteCaptcha" placeholder="短信验证码"></el-input>
          </el-col>
          <el-col :span="8">
            <el-button @click="sendSmsCode">发送验证码</el-button>
          </el-col>
        </el-row>
      </el-form-item> -->
      <el-button style="height:40px;" type="primary" native-type="submit">登录</el-button>
    </el-form>
  </el-container>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      form: {
        phoneNumber: '',
        password: '',
        // imgCaptcha: '',
        // captchaKey: '',
        // noteCaptcha: ''
      },
      captchaImage: '',
    };
  },
  // mounted() {
  //   this.refreshCaptcha(); // 页面加载时刷新验证码
  // },
  methods: {
    onSubmit() {
      axios.post('aicoach/api/v1/mobileLoginPwd/', {
        phone_number: this.form.phoneNumber,
        password: this.form.password
      })
      .then(response => {
        const loginData = response.data;
        console.log(loginData)
        // 存储 token 到 localStorage
        sessionStorage.setItem('token', loginData.token);
        sessionStorage.setItem('tokenExpiresAt', loginData.expires_at);
        sessionStorage.setItem('fromLogin', 'true');
        this.$message.success(loginData.success);
        this.$router.push('/module');
      })
      .catch(error => {
        this.$message.error(error);
      });
    }
      // 提交表单逻辑
      // axios.post('/aicoach/api/v1/mobileLogin/', {
      //   phone_number: this.form.phoneNumber,
      //   sms_code: this.form.noteCaptcha
      // })
      // .then(response => {
      //   const loginData = response.data;
      //   console.log(loginData)
      //   // 存储 token 到 localStorage
      //   localStorage.setItem('token', loginData.token);
      //   localStorage.setItem('tokenExpiresAt', loginData.expires_at);
      //   localStorage.setItem('fromLogin', 'true');
      //   this.$message.success(loginData.success);
      //   this.$router.push('/dashboard');
      // })
      // .catch(error => {
      //   this.$message.error(error);
      // });
  },
    // refreshCaptcha() {
    //   // 向后端请求新的验证码图片
    //   axios.post('/aicoach/api/v1/getCaptcha/')
    //     .then(response => {
    //       // 假设响应中包含验证码图片的URL
    //       console.log(response.data)
    //       this.captchaImage = response.data.captcha_image;//"http://127.0.0.1:8000/"+
    //       this.form.captchaKey = response.data.captcha_key;
    //     })
    //     .catch(error => {
    //       this.$message.error(error);
    //       console.error('Error refreshing captcha:', error);
    //     });
    // },

    // sendSmsCode() {
    //   // 向后端发送请求以触发短信验证码发送
    //   axios.post('/aicoach/api/v1/sendSmsCode/', {
    //     phone_number: this.form.phoneNumber,
    //     captcha_1: this.form.captchaKey,
    //     captcha_0: this.form.imgCaptcha, // 这里假设您需要传递图片验证码
    //   })
    //   .then(response => {
    //     if (response.data.success) {
    //       this.$message.success(response.data.success);
    //     } else {
    //       this.$message.warning(response.data.error);
    //     }
    //   })
    //   .catch(error => {
    //     this.$message.error(error);
    //     console.error('Error sending SMS code:', error);
    //   });
    // }
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 5% auto;
  padding: 35px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 12px 25px -10px rgba(0, 0, 0, 0.1), 0 4px 10px -10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.login-container::before {
  content: '';
  position: absolute;
  top: -2px; right: -2px; bottom: -2px; left: -2px;
  z-index: -1;
  background: #fff;
  border-radius: 15px;
  background: linear-gradient(145deg, #cacaca, #f0f0f0);
  box-shadow: 0 12px 25px -10px rgba(0, 0, 0, 0.1), 0 4px 10px -10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
}

.login-container:hover::before {
  top: 0; right: 0; bottom: 0; left: 0;
}

.el-form {
  margin: 25px 0;
}

.el-form-item {
  margin-bottom: 30px;
}

.el-form-item label {
  font-size: 16px;
  font-weight: 500;
  color: #303133;
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.el-input__inner {
  height: 40px;
  padding: 9px 15px;
  border: 1px solid #dcdfe6;
  border-radius: 20px;
  font-size: 15px;
  transition: border-color .3s;
}

.el-input__inner:focus {
  border-color: #409eff;
}

.el-button {
  height: 30px;
  padding: 9px 18px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 20px;
  color: #fff;
  background-color: #409eff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color .3s, box-shadow .3s;
}

.el-button:hover {
  background-color: #337ecc;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.captcha-img {
  width: 100%;
  height: auto;
  border-radius: 6px;
  cursor: pointer;
  transition: box-shadow .3s;
}

.captcha-img:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.el-row {
  margin-bottom: 0;
}

.el-col {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .login-container {
    margin: 10% auto;
    width: auto;
    padding: 25px;
  }
}

/* Titles and Headings */
h1 {
  text-align: center;
  color: #409eff;
  text-shadow: 0 2px 4px rgba(0,0,0,0.2);
  margin-bottom: 20px;
  font-size: 28px;
}

/* Input focus and hover effects */
.el-input .el-input__inner:hover {
  border-color: #a1c4fd;
}

/* Optional: Add some hover effects to buttons for better feedback */
.el-button.el-button--primary:not(.is-disabled):not(.is-loading):hover,
.el-button.el-button--primary:not(.is-disabled):not(.is-loading):focus {
  background-color: #66b1ff;
  border-color: #66b1ff;
}

.el-button.el-button--primary.is-active,
.el-button.el-button--primary:active {
  background-color: #3a8ee6;
  border-color: #3a8ee6;
}
</style>
