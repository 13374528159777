<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  mounted() {
  // 检查用户是否已经登录
  if (localStorage.getItem('token') && new Date(localStorage.getItem('tokenExpiresAt')) > new Date()) {
    // 如果token存在且未过期，可以选择保持在当前页面，或跳转到特定页面
  } else {
    // 如果token不存在或已过期，跳转到登录页面
    this.$router.push('/login');
  }
}
}
</script>

<style>
/* 去掉某些的边距 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
