<template>
    <iframe class="game" src="https://test2.dd2a.net/game" style="height: 95vh;width: 100vw;margin: 0;padding: 0;"></iframe>
  </template>
  
  <script>
  export default {
    methods: {
    }
  }
  </script>
  
  <style scoped>
  @media (orientation: landscape) {
    .game{
      width: 100%;
      height: 100%;
    }
  }
  </style>