<template>
  <div class="chat-container">
    <el-input
      class="input-field"
      v-model="input"
      placeholder="Enter Message..."
      @keyup.enter="handleEnter"
    />
    <el-button
      class="send-button"
      :disabled="isButtonDisabled"
      :style="{ opacity: input.trim().length && !isRecording  > 0 ? '1' : '0.2' }"
      @click="handleEnter"
    >
      <el-icon class="icon"><Top /></el-icon>
    </el-button>
    <el-button
      class="voice-button"
      :disabled="VoiceButtonDisabled"
      @click="toggleRecording"
    >
    <el-icon><Microphone /></el-icon>
    </el-button>
    <!-- 录音指示器 -->
    <div class="recording-indicator" v-if="isRecording">
      <div class="indicator-content">
        <el-icon><Microphone /></el-icon>
        <p>正在录音...</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: '',
      isButtonDisabled: false, // 控制发送按钮是否禁用
      VoiceButtonDisabled: false, // 控制语音按钮是否禁用
      isRecording: false, // 追踪录音状态
      mediaRecorder: null,
      audioChunks: [],
    };
  },
  methods: {
    handleEnter() {
      if (this.input.trim()) {
        this.$emit('send', { type: 'text', content: this.input });
        this.input = '';
        this.isButtonDisabled = true; // 发送消息后禁用按钮
        this.VoiceButtonDisabled = true;
      }
    },
    async toggleRecording() {
      if (!this.isRecording) {
        this.isButtonDisabled = true;
        await this.startRecording();
      } else {
        this.VoiceButtonDisabled = true;
        this.stopRecording();
      }
    },
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];

        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(audioBlob);
          const base64Audio = await this.blobToBase64(audioBlob);
          this.$emit('send', { type: 'voice', content: base64Audio, audioUrl: audioUrl });
        };

        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error('Error starting audio recording:', error);
      }
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // 去除Base64字符串前的数据URI前缀（例如 "data:audio/wav;base64,"）
          const base64String = reader.result.replace(/^data:.+;base64,/, '');
          resolve(base64String);
        };
        reader.readAsDataURL(blob);
      });
    }
  }
}
</script>

<style scoped>
.chat-container {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #c2c5ca;
  border-radius: 12px;
  padding: 5px 10px;
  max-width: 700px;
  margin: 0 auto;
  height: 54px;
}

.input-field {
  flex-grow: 1;
  margin-right: 10px;
}

:deep(.el-input__wrapper) {
  border: none !important;
  box-shadow: none !important;
}

:deep(.el-input__wrapper.is-focus) {
  border: none !important;
  box-shadow: none !important;
}
.send-button, .voice-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0 5px;
}

.send-button{
  width: 30px;
  height: 30px;
  font-size: 20px;
  background-color: black;
  color: white;
}

.voice-button{
  font-size: 20px;
  color: black;
}

.send-button:disabled {
  background-color: black;
  color: white;
  opacity: 0.2;
}

/* 鼠标悬浮时图标按钮的样式 */
.send-button:hover,
.send-button:active,
.send-button:focus {
  background-color: black !important;
}
.voice-button:hover,
.voice-button:active,
.voice-button:focus {
  background-color: transparent !important;
}
.send-button:hover .el-icon,
.send-button:active .el-icon,
.send-button:focus .el-icon {
  color: white !important;
}
.voice-button:hover .el-icon,
.voice-button:active .el-icon,
.voice-button:focus .el-icon {
  color: black !important;
}
.recording-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 确保覆盖其他元素 */
  pointer-events: none;
}

.indicator-content {
  text-align: center;
  background-color: white;
  padding: 20px;
  margin-left: 240px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}

.indicator-content p {
  margin-top: 10px;
  color: black;
  font-size: 16px;
}
</style>