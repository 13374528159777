<template>
  <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet">
  <div class="chat-window">
    <!-- 当没有消息时显示默认文字 -->
    <div v-if="showWelcome || (!hasActualMessages && !isLoading)" class="welcome-message">
      <img src="../assets/FULAERQI.png" alt="Welcome Image" class="icon-image" />
      <p>Let's draw together !</p>
    </div>

    <!-- 消息列表 -->
    <div v-else>
      <el-card class="chat-message" v-for="message in messages" :key="message.id">
        <!-- 用户文本消息 -->
        <div v-if="message.sender === 'user' && message.type === 'text'" class="user-message">
          <span class="message-icon user-icon">{{userInitials}}</span>
          <p style="font-weight: 700;">You</p>
          {{ message.content }}
        </div>
        
        <!-- 用户语音消息 -->
        <div v-if="message.sender === 'user' && message.type === 'voice'" class="user-message">
          <span class="message-icon user-icon">{{userInitials}}</span>
          <p style="font-weight: 700;">You</p>
          <div class="voice-message-container" @click="toggleAudioPlayback(message.id)">
            <i :class="isPlaying ? 'fa-solid fa-pause' : 'fa-solid fa-volume-high'"></i>
            <audio :src="message.content" class="hidden" @ended="audioEnded" :ref="'audioPlayer' + message.id"></audio>
          </div>
        </div>

        <div v-if="message.sender === 'assistant'" class="assistant-message">
          <!-- Icon and text side by side -->
          <div class="message-header">
            <span class="message-icon assistant-icon">
              <img src="../assets/FULAERQI.png" alt="Assistant's Icon" class="assistant-icon-image" />
            </span>
            <p class="message-text">assistant</p>
          </div>
          <!-- Loading message or image in a separate line -->
          <div class="message-content">
            <span v-if="message.isLoading" class="loading-message">{{ message.content }}</span>
            <img v-else :src="message.content" alt="Assistant's Reply" />
          </div>
        </div>

      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    messages: Array,
    isLoading: Boolean,
    showWelcome: Boolean,
    isPolling: Boolean,
    userInitials:String
  },
  computed: {
    hasActualMessages() {
      return this.messages.length > 0;
    }
  },
  data() {
    return {
      isPlaying: false, // 标记是否正在播放
    };
  },
  methods: {
    toggleAudioPlayback(id) {
      const audioPlayer = this.$refs['audioPlayer' + id][0];
      if (!this.isPlaying) {
        audioPlayer.play();
        this.isPlaying = true;
      } else {
        audioPlayer.pause();
        audioPlayer.currentTime = 0; // 重置音频位置
        this.isPlaying = false;
      }
    },
    audioEnded() {
      this.isPlaying = false; // 当音频播放结束时，重置播放状态
    },
  },
}
</script>

<style scoped>
.chat-window {
  position: relative; 
  width: 700px; 
  margin: 0 auto; 
  min-height: 1000px;
}

/* 消息卡片样式 */
.chat-message {
  width: 100%; 
  border: none;
}

.assistant-message img {
  width: 576px;
  height: 448px;
  object-fit: contain; 
  display: block;
  margin-left: 0;
}

/* 用户消息样式 */
.user-message {
  background-color: #f0f3f9;
  text-align: left;
  line-height: 30px;
}


.welcome-message {
  color: #000; 
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1000; /* 确保这个元素在最上面 */
  font-weight: 700;
  font-size: 1.5rem;
}

.message-icon {
  display: block;
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 30px;
  height: 30px;
  line-height: 30px; 
  border-radius: 50%; 
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 12px; 
}

.user-icon {
  background-color: #A74035; /* 用户消息的颜色 */
}

.assistant-icon {
  background-color: #A967FC; /* 助手消息的颜色 */
  width: 30px; 
  height: 30px;
  border-radius: 50%; 
  overflow: hidden; 
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 为了美观，调整消息文本的样式 */
.user-message, .assistant-message {
  position: relative; 
  padding-left: 40px;
}
.assistant-message {
  position: relative; /* 添加相对定位 */
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: #f0f3f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 50px;
  padding-left: 40px;
}
.loading-message {
  position: absolute; 
  top: 0;
  left: 0;
  color: #909399;
  font-style: italic;
  animation: colorFade 2s infinite;
  z-index: 10;
  white-space: nowrap;
}
/* 渐变动画 */
@keyframes colorFade {
  0%, 100% {
    color: #909399;
  }
  25% {
    color: #8c64d6;
  }
  50% {
    color: #2196f3; 
  }
  75% {
    color: #da51a1;
  }
}
.icon-image{
  width: 80px;
}
.icon-image::after {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
}
.assistant-icon img {
  width: 100%;
  height: auto;
}
.message-text {
  font-weight: 700;
  height: 30px;
  line-height: 30px;
}
.message-header {
  display: flex;
  align-items: center;
}

.message-content {
  position: relative;
  width: 100%;
}

/* 语音消息图标样式 */
.voice-message-container{
  display: flex; 
  justify-content: center;
  align-items: center; 
  width: 40px; 
  height: 40px; 
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  font-size: 15px;
}

.hidden {
  display: none;
}
</style>
