<template>
    <iframe class="mbti" src="https://test2.dd2a.net/mbti" style="height: 100vh;width: 100vw;margin: 0;padding: 0;"></iframe>
  </template>
  
  <script>
  export default {
    methods: {
    }
  }
  </script>
  
  <style scoped>
  @media (orientation: landscape) {
    .mbti{
      width: 100%;
      height: 100%;
    }
  }
  </style>