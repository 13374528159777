<template>
  <el-container style="height: 100vh;">

    <!-- 侧边栏 -->
    <el-aside style="width:260px; display: flex; flex-direction: column; height: 100vh; background-color:#000;">
      <el-button class="button-with-icon" @click="handleCreateNew">
        <div>
          <img src="../assets/FULAERQI.png" alt="Image" class="left-image" style="width: 30px; height: 30px;" >
        </div>
        <span>FULAERQI</span>
        <el-icon class="button-icon"><Edit /></el-icon>
      </el-button>
      <div class="book-list" style="flex-grow: 1; overflow-y: auto;">
        <Sidebar
          @selectBook="handleSelectBook"
          @updateBookTitle="updateBookTitle"
          @deleteBook="deleteBook"
          :books="books"
          :activeBookId="activeBookId"/>
      </div>
      <!-- 用户信息区域，点击时切换菜单显示状态 -->
      <div class="user-info" @click="toggleLogout">
        <div style="height:30px;width: 30px;background-color: #A74035;border-radius: 50%;margin-left: 8px;line-height: 30px;color:white;font-size: 12px;">{{ userInitials }}</div>
        <span class="username">{{ username }}</span>
      </div>

      <!-- 弹出菜单，包含注销选项 -->
      <el-menu v-show="showLogout" class="user-menu" @click="logout">
        <el-menu-item class="menu-item" index="1">
          <el-icon style="margin-right: 15px;"><SwitchButton /></el-icon>Log out
        </el-menu-item>
      </el-menu>
      
      
    </el-aside>

    <!-- 聊天窗口和输入框 -->
    <el-container>
      <el-main ref="main">
        <DialogWindow :messages="messages" :userInitials="userInitials" :isLoading="loadingMessages" :showWelcome="showWelcomePage" :isPolling="isPolling"/>
      </el-main>

      <el-footer style="padding: 0; height: 70px;">
        <div class="scroll-to-bottom" @click="ToBottom" v-if="showScrollDownButton">
          <el-icon>
            <Bottom />
          </el-icon>
        </div>
        <DialogInput ref="dialogInput" @send="handleSendMessage" />
      </el-footer>

    </el-container>

  </el-container>
</template>

<script>
import Sidebar from './SideBar.vue';
import DialogWindow from './DialogWindow.vue';
import DialogInput from './DialogInput.vue';
import axios from 'axios';

export default {
  components: {
    Sidebar,
    DialogWindow,
    DialogInput,
  },
  data() {
    return {
      books: [],
      messages: [],
      nextId: 1,
      token: '',
      tokenExpiresAt: '',
      bookId: null,
      pageId: null,
      dialogId: null,
      resourcesCreated: false,// 标识是否已经创建了书籍、页面和对话
      activeBookId: null,
      pollingTimerId: null,
      isPolling: false,
      loadingMessages: true,
      showWelcomePage: false,
      showScrollDownButton: false,
      showLogout:false,
      username:'',
      userInitials:'',
      isFirstMessageVoice:false,
    };
  },
  created() {
    this.token = sessionStorage.getItem('token');
    this.tokenExpiresAt = sessionStorage.getItem('tokenExpiresAt');
    const fromLogin = sessionStorage.getItem('fromLogin');
    if (!this.token || new Date(this.tokenExpiresAt) <= new Date()) {
      this.$router.push('/login');
      return;
    }
    if (fromLogin === 'true') {
      // 从登录页面来的，显示欢迎页面
      this.showWelcomePage = true;
      sessionStorage.removeItem('fromLogin'); // 清除标识
    } else {
      const savedBookId = sessionStorage.getItem('activeBookId');
      if (savedBookId) {
        this.activeBookId = savedBookId;
        this.handleSelectBook(savedBookId);
      }else {
      this.showWelcomePage = true;
      }
    }
    this.fetchBooks(); // 组件创建时获取书籍列表
    this.fetchUsername();
  },
  mounted() {
    this.$nextTick(() => {
      // screen.orientation.lock('landscape');
      const mainElement = this.$refs.main.$el;
      if (mainElement) {
        console.log("1")
        mainElement.scrollTop = mainElement.scrollHeight;
        mainElement.addEventListener('scroll', this.handleScroll);
      }
    });
  },
  beforeUnmount() {
    if (this.pollingTimerId) {
      this.stopPollingForImage();
    }
    screen.orientation.unlock();
    const mainElement = this.$refs.main.$el;
    if (mainElement) {
      mainElement.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    toggleLogout(){
      this.showLogout = !this.showLogout;
    },
    logout() {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('tokenExpiresAt');
      sessionStorage.removeItem('activeBookId');
      sessionStorage.removeItem('fromLogin');
      this.$router.push('/login');
    },
    fetchUsername() {
      axios.post('/aicoach/api/v1/getUserProfile/', {
        token: this.token
      })
      .then(response => {
        const userMessage = response.data;
        console.log(userMessage);
        this.username = userMessage.username;
        this.userInitials = this.username.substring(0, 2).toUpperCase();
      })
    },
    // 处理新建操作
    handleCreateNew() {
      this.messages = []; // 清空对话消息
      this.activeBookId = null;
      this.resourcesCreated = false; // 重置资源创建标志
      this.stopPollingForImage();
      sessionStorage.removeItem('activeBookId');
      // 清空输入框
      if (this.$refs.dialogInput) {
        this.$refs.dialogInput.input = '';
      }
    },
    
    // 处理选择现有书籍的操作
    handleSelectBook(bookId) {
      if (this.pollingTimerId) {
        this.stopPollingForImage();
      }
      if (this.$refs.dialogInput) {
        this.$refs.dialogInput.input = '';
      }
      this.showWelcomePage = false;
      this.loadingMessages = true;
      this.messages = []; // 清空对话消息
      this.resourcesCreated = true;
      // 找到发送消息的子组件并重新启用其发送按钮
      if (this.$refs.dialogInput) {
        this.$refs.dialogInput.isButtonDisabled = false;
      }
      this.activeBookId = bookId.toString(); // 设置激活的书籍ID
      sessionStorage.setItem('activeBookId', this.activeBookId);

      // 获取选中书籍的页面信息
      axios.post('/aicoach/api/v1/getBookPages/', {
        token: this.token,
        book_id: bookId
      })
      .then(response => {
        const pages = response.data.pages;
        console.log(pages);
        if (pages.length > 0) {
          const firstPage = pages[0];
          this.pageId = firstPage.id;

          return this.fetchDialogMessages(bookId, this.pageId);
        } else {
          throw new Error('No pages found for this book');
        }
      })
      .then(messages => {
        // 更新messages数组来显示对话消息
        console.log(messages)
        for (let i = 1; i < messages.length; i++) {
          const message = messages[i];
          this.messages.push({
            id: message.id,
            content: message.content_type === 'text' ? message.content : message.voice_file,
            sender: message.message_type, // 使用 message_type 作为 sender
            type: message.content_type,
          });
        }
        this.$nextTick(() => {
          const mainElement = this.$refs.main.$el;
          if (mainElement) {
            mainElement.scrollTop = mainElement.scrollHeight;
          }
        });
        this.loadingMessages = false;
      })
      .catch(error => {
        console.error('Error fetching book pages or messages:', error);
        this.loadingMessages = false;
      });
    },
    fetchDialogMessages(bookId, pageId) {
      return axios.post('/aicoach/api/v1/createUserBookDialog/', {
        token: this.token,
        book_id: bookId,
        page_id: pageId
      })
      .then(response => {
        console.log(response.data);
        this.dialogId = response.data.dialog_id;
        // 对消息进行排序
        const sortedMessages = response.data.messages.sort((a, b) => a.id - b.id);
        // 返回排序后的对话消息
        return sortedMessages;
      })
      .catch(error => {
        console.error('Error fetching dialog messages:', error);
        throw error;
      });
    },
    handleSendMessage(newMessage) {
      // 如果消息长度超过最大长度，则截取
      let trimmedMessage = 'New book';
      if (newMessage.type === 'text') {
        trimmedMessage = newMessage.content.length > 10 ? newMessage.content.slice(0, 10) : newMessage.content;
        this.messages.push({
          id: this.nextId++,
          content: newMessage.content,
          sender: 'user',
          type: 'text', 
        });
      } else if (newMessage.type === 'voice' && !this.resourcesCreated) {
        this.isFirstMessageVoice = true;
        // 如果是语音消息
        this.messages.push({
          id: this.nextId++,
          content: newMessage.audioUrl, // 存储音频URL以便播放
          sender: 'user',
          type: 'voice',
        });
      } else if (newMessage.type === 'voice') {
        // 如果是语音消息
        this.messages.push({
          id: this.nextId++,
          content: newMessage.audioUrl, // 存储音频URL以便播放
          sender: 'user',
          type: 'voice',
        });
      }
      setTimeout(() => {
        this.ToBottom();
      }, 500);
      this.showWelcomePage = false;
      // 如果资源尚未创建，则创建它们
      if (!this.resourcesCreated) {
        this.createResources(trimmedMessage).then(() => {
          // 资源创建后发送完整的消息
          this.sendDialogMessage(this.dialogId, newMessage);
        });
      } else {
        // 否则，只发送消息
        this.sendDialogMessage(this.dialogId, newMessage);
      }
    },
    createResources(trimmedMessage) {
      // 创建书籍的逻辑
      return axios.post('/aicoach/api/v1/addUserBook/', {
        token: this.token,
        title: trimmedMessage,
        description: 'description'
      })
      .then(response => {
        const bookData = response.data;
        console.log(bookData);
        this.bookId = bookData.id;
        
        // 创建页面的逻辑
        return axios.post('/aicoach/api/v1/createUserBookPage/', {
          token: this.token,
          book_id: this.bookId,
          page_size: '1x1'
        });
      })
      .then(response => {
        const pageData = response.data;
        console.log(pageData);
        this.pageId = pageData.pages[0].id;
        
        // 创建对话的逻辑
        return axios.post('/aicoach/api/v1/createUserBookDialog/', {
          token: this.token,
          book_id: this.bookId,
          page_id: this.pageId,
        });
      })
      .then(response => {
        const dialogData = response.data;
        console.log(dialogData);
        this.dialogId = dialogData.dialog_id;
        this.resourcesCreated = true;
        this.activeBookId = this.bookId.toString();
        sessionStorage.setItem('activeBookId', this.activeBookId);

        this.fetchBooks();
      })
      .catch(error => {
        console.error('Error creating resources:', error);
      });
    },
    sendDialogMessage(dialogId, newMessage) {
      // 发送消息到对话的逻辑
      axios.post('/aicoach/api/v1/createDialogBookMessageCkafka/', {
        token: this.token,
        dialog_id: dialogId,
        content: newMessage.content,
        content_type: newMessage.type,
        filename: 'filename',
        priority_level: 'normal'
      })
      .then(response => {
        // 消息成功发送到对话
        const dialogBookMessage = response.data;
        console.log(dialogBookMessage);
        if(this.isFirstMessageVoice){
          let newTitle = dialogBookMessage.content.length > 10 ? dialogBookMessage.content.slice(0, 18) : dialogBookMessage.content;
          let bookData = {
            bookId: this.bookId,
            newTitle: newTitle
          };
          this.updateBookTitle(bookData);
        }
        this.startPollingForImage(dialogBookMessage.unique_id);
      })
      .catch(error => {
        console.error('Error sending dialog message:', error);
      });
    },
    startPollingForImage(uniqueId) {
      // 如果已经在轮询，则不再启动新的轮询
      if (this.isPolling) return;

      this.isPolling = true;
      this.messages.push({
        id: this.nextId++, 
        content: 'Creating image, please wait...',
        sender: 'assistant',
        isLoading: true
      });
      setTimeout(() => {
        this.ToBottom();
      }, 500);
      const pollInterval = 10000; // 每10秒轮询一次
      this.pollingTimerId = setInterval(() => {
        axios.post('/aicoach/api/v1/getImageCkafka/', { 
          token: this.token,
          dialog_id: this.dialogId,
          unique_id: uniqueId 
        })
        .then(response => {
          const ImageData = response.data;
          console.log(ImageData)
          if (ImageData.status === 'completed') {
            // 图像已生成
            clearInterval(this.pollingTimerId);
            this.isPolling = false;
            this.pollingTimerId = null;
            // 找到加载消息并替换为图像
            const loadingMessage = this.messages.find(m => m.isLoading);
            if (loadingMessage) {
              loadingMessage.content = ImageData.img_url;
              loadingMessage.isLoading = false; 
              setTimeout(() => {
                this.ToBottom();
              }, 1000);
            }
            // 找到发送消息的子组件并重新启用其发送按钮
            this.$refs.dialogInput.isButtonDisabled = false;
            this.$refs.dialogInput.VoiceButtonDisabled = false;
          }
        })
        .catch(error => {
          this.isPolling = false;
          clearInterval(this.pollingTimerId);
          console.error('Polling error:', error);
        });
      }, pollInterval);
    },
    stopPollingForImage() {
      if (this.pollingTimerId) {
        clearInterval(this.pollingTimerId);
        this.pollingTimerId = null;
        this.isPolling = false;
      }
    },
    fetchBooks() {
      // 从服务器获取书籍列表
      axios.post('/aicoach/api/v1/getUserBooks/', {
        token: this.token,
      })
      .then(response => {
        const booksData = response.data;
        console.log(booksData);
        // 按照updated_at时间戳降序排序书籍
        this.books = booksData.books.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      })
      .catch(error => {
        console.error(error);
      });
    },
    ToBottom() {
      const mainElement = this.$refs.main.$el;
      if (mainElement) {
        mainElement.scrollTop = mainElement.scrollHeight;
      }
      this.showScrollDownButton = false;
    },
    handleScroll() {
      const mainElement = this.$refs.main.$el;
      if (mainElement) {
        const isScrolledToBottom = mainElement.scrollHeight - mainElement.clientHeight <= mainElement.scrollTop ;
        this.showScrollDownButton = !isScrolledToBottom;
      }
    },
    updateBookTitle(bookData) {
      axios.post('aicoach/api/v1/renameUserBook/', {
        token: this.token,
        book_id: bookData.bookId,
        book_title: bookData.newTitle,
      })
      .then(response => {
        console.log('Book title updated:', response.data);
        this.isFirstMessageVoice = false;
        this.fetchBooks();
      })
      .catch(error => {
        console.error('Error updating book title:', error);
      });
    },
    deleteBook(bookId){
      axios.post('aicoach/api/v1/deleteUserBook/', {
        token: this.token,
        book_id: bookId,
      })
      .then(response => {
        console.log(response.data);
        // 如果删除的是当前选中的书籍，则显示欢迎页面
        if (this.activeBookId === bookId.toString()) {
          this.messages = [];
          this.resourcesCreated = false;
          this.activeBookId = null;
          this.stopPollingForImage();
          sessionStorage.removeItem('activeBookId');
        }
        this.fetchBooks();
      })
      .catch(error => {
        console.error(error);
      });
    }
  }
}
</script>
<style scoped>
.el-aside * {
  border: none;
}
.button-with-icon {
  width: 100%;
  height: 60px;
  background-color: #000;
  color: #fff;
  font-size: 16px; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  flex-shrink: 0;
}
.el-button:hover{
  background-color: #555; 
  color: #fff;
}
.el-button:focus, .el-button:active {
  background-color: #000; 
  color: #fff;
}
.button-icon {
  font-size: 20px; 
  margin-left: 80px; 
}
.el-aside ::-webkit-scrollbar {
  background-color: #000;
}
.el-aside ::-webkit-scrollbar-thumb {
  background-color: #555; 
}

.scroll-to-bottom {
  position: fixed; 
  bottom: 100px; 
  right: 45%;
  z-index: 10;
  font-size: 18px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.left-image {
  border-radius: 50%; /* 将边框半径设置为50%以创建圆形 */
  background-color: white; /* 设置白色背景 */
  margin-right: 5px;
}

.user-info {
  height: 50px;
  line-height: 50px;
  padding: 10px;
  margin-top: auto; /* 推到底部 */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.username {
  margin-left: 10px;
  color: white;
  font-size: 15px;
}

/* 弹出菜单样式 */
.user-menu {
  background-color: #333;
  position: absolute; 
  bottom: 50px;
  width: 260px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border-radius: 10px;
  z-index: 100;
}
.menu-item:hover{
  background-color: #555;
}
.user-info:hover{
  background-color: #333;
}
.menu-item{
  color: #fff;
}
.logout-icon{
  width: 20px;
  height: 20px;
}
</style>
