<template>
  <div id="module">
    <h1 class="welcome-title">音乐平台</h1>
    <div v-if="audioFile" style="margin-top: 200px;">
      <audio controls :src="apiUrl + audioFile.media_module"></audio>
    </div>
    <div v-else>
      <p>正在加载中...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      audioFile: null,
      apiUrl: process.env.API_ROOT || 'https://test2.dd2a.net',
      token: '',
      tokenExpiresAt: '',
    };
  },
  mounted() {
    this.token = sessionStorage.getItem('token');
    this.tokenExpiresAt = sessionStorage.getItem('tokenExpiresAt');
    if (!this.token || new Date(this.tokenExpiresAt) <= new Date()) {
      this.$router.push('/login');
      return;
    }
    this.getAudioFile();
  },
  methods: {
    getAudioFile() {
      axios
        .get('aicoach/api/v1/getModuleFiles/', {
          params: {
            type: 1,
            token: this.token
          },
        })
        .then(response => {
          if (response.data.data) {
            this.audioFile = response.data.data;
          }
        });
    },
  },
};
</script>

<style scoped>
#module {
  padding: 50px 0;
}
.welcome-title {
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}
</style>