import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/UserLogin.vue';
import Module from '../views/AppModule.vue';
import Dashboard from '../views/DashBoard.vue';
import DialogWindow from '../views/DialogWindow.vue';
import Music from '../views/UserMusic.vue';
import Video from '../views/UserVideo.vue';
import Games from '../views/UserGames.vue';
import Mbti from '../views/UserMbti.vue';


const routes = [
  { path: '/login', component: Login },
  {
    path: '/module',
    name:'module',
    component: Module,
  },
  {
    path: '/dashboard',
    name:'dashboard',
    component: Dashboard,
    children: [
      {
        path: '/dialogWindow',
        component: DialogWindow,
      },
    ],
  },
  {
    path: '/music',
    name:'music',
    component: Music,
  },
  {
    path: '/video',
    name:'video',
    component: Video,
  },
  {
    path: '/gotoGames',
    name:'games',
    component: Games,
  },
  {
    path: '/gotoMbti',
    name:'mbti',
    component: Mbti,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;