<template>
  <el-menu
    background-color="#333"
    text-color="#fff"
    active-text-color="#ffd04b"
    :default-active="activeBookId"
  >
    <el-menu-item
      v-for="book in books"
      :key="book.id"
      :index="book.id.toString()"
      class="menu-item"
      @click="navigateTo(book.id)"
    >
      <div class="menu-item-content">
        <!-- 判断是否正在编辑这本书 -->
        <template v-if="editingBookId === book.id">
          <input
            type="text"
            v-model="tempBookTitle"
            @click.stop
            @blur="finishEditing(book.id)"
            @keyup.enter="finishEditing(book.id)"
            style="height:30px;margin-top: 13px;"
          />
        </template>
        <template v-else>
          <span>
            {{ book.title.length > 10 ? book.title.slice(0, 10) : book.title }}
          </span>
        </template>
        <span class="icons" :class="{ 'icons-visible': activeBookId === book.id.toString() }">
          <el-tooltip class="item" effect="dark" content="Edit" placement="top">
            <el-icon @click.stop="startEditing(book.id, book.title)">
              <Edit />
            </el-icon>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="Delete" placement="top">
            <el-icon @click.stop="handleDelete(book.id)">
              <Delete />
            </el-icon>
          </el-tooltip>
        </span>
      </div>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  props: {
    books: Array,
    activeBookId: String
  },
  data() {
    return {
      editingBookId: null,
      tempBookTitle: '',
      originalTitle: '',
    };
  },
  methods: {
    navigateTo(bookId) {
      this.$emit('selectBook', bookId);
    },
    startEditing(bookId, title) {
      this.editingBookId = bookId;
      this.tempBookTitle = title;
      this.originalTitle = title;
    
      this.$nextTick(() => {
        // 查找当前组件中的所有输入框，并聚焦到第一个
        const inputs = this.$el.querySelectorAll('input');
        if (inputs.length) inputs[0].focus();
      });
    },
    finishEditing(bookId) {
      // 检查是否有更改
      if (this.tempBookTitle !== this.originalTitle) {
        // 如果有更改，则发出事件
        this.$emit('updateBookTitle', { bookId: bookId, newTitle: this.tempBookTitle });
      }
      // 无论是否更改，都重置编辑状态
      this.editingBookId = null;
      this.tempBookTitle = '';
      this.originalTitle = '';
    },
    handleDelete(bookId) {
      this.$confirm('This will delete book.', 'Delete book?', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then(() => {
        this.$emit('deleteBook', bookId);
      }).catch(() => {});
    },
  }
}
</script>

<style scoped>
.menu-item {
  display: flex;
  align-items: center;
  position: relative;
}

.menu-item-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icons {
  margin-left: auto; 
  align-items: center;
  visibility: hidden;
}

.menu-item:hover .icons,
.icons-visible {
  visibility: visible;
}

.el-icon {
  font-size: 15px !important; 
  margin-right: 0 !important; 
  cursor: pointer;
}

.el-tooltip {
  display: inline-block;
}


.el-menu-item.is-active {
  background-color: #5c5e61;
  color: #fff;
}

.el-menu-item {
  height: 40px;
  background-color: #000;
  color: #fff;
}

.el-menu-item:not(.is-active):hover {
  background-color: #555; 
}

</style>