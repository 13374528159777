<template>
  <div id="module">
    <div class="container">
      <h1 class="welcome-title">翰林智绘</h1>
      <p class="welcome-subtitle">为学生提供音乐、视频、游戏和绘图创作工具</p>

      <div class="menu">
        <button @click="goTo('music')" class="menu-button">
          <i class="fas fa-music" ></i> 音乐
        </button>
        <button @click="goTo('video')" class="menu-button">
          <i class="fas fa-video"></i> 视频
        </button>
        <button @click="goTo('games')" class="menu-button">
          <i class="fas fa-gamepad"></i> 游戏
        </button>
        <button @click="goTo('dashboard')" class="menu-button">
          <i class="fas fa-paint-brush"></i> 绘图
        </button>
        <button @click="goTo('mbti')" class="menu-button">
          <i class="fas fa-puzzle-piece"></i> 性格
        </button>
      </div>
    </div>
    <router-view />
  </div>
</template>
  
<script>
export default {
  methods: {
    goTo(module) {
      this.$router.push({ name: module });
    }
  }
}
</script>

<style scoped>
#module {
  background-color: #ccc8c8;
  padding: 50px 0;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.welcome-title {
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.welcome-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  color: #666;
  margin-bottom: 50px;
}

.menu {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.menu-button {
  padding: 18px 32px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center; /* 水平居中文本 */
}

.menu-button:hover {
  background-color: #45a049;
}
@media (max-width: 768px) {
  /* Button Size Adjustments */
  .menu-button {
    font-size: 14px; /* Reduce button font size */
    padding: 12px 24px; /* Adjust button padding */
  }

  /* Menu Gap Adjustment */
  .menu {
    gap: 15px; /* Reduce menu gap for better spacing */
  }
}
</style>