<template>
  <div id="module">
    <h1 class="welcome-title">视频平台</h1>
    <div v-if="videoFile" style="margin-top: 200px">
      <video style="width: 960px;height: 540px;" controls :src="apiUrl + videoFile.media_module"></video>
    </div>
    <div v-else>
      <p>正在加载中...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      videoFile: null,
      apiUrl: process.env.API_ROOT || 'https://test2.dd2a.net',
      token: '',
      tokenExpiresAt: '',
    };
  },
  mounted() {
    this.token = sessionStorage.getItem('token');
    this.tokenExpiresAt = sessionStorage.getItem('tokenExpiresAt');
    if (!this.token || new Date(this.tokenExpiresAt) <= new Date()) {
      this.$router.push('/login');
      return;
    }
    this.getVideoFile();
  },
  methods: {
    getVideoFile() {
      axios
        .get('aicoach/api/v1/getModuleFiles/', {
          params: {
            type: 2,
            token: this.token
          },
        })
        .then(response => {
          if (response.data.data) {
            this.videoFile = response.data.data;
          }
        });
    },
  },
};
</script>

<style scoped>
#module {
  padding: 50px 0;
}
.welcome-title {
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}
</style>